import styled from '@emotion/styled'
import {
  phone_max,
  phablet_max,
  tablet_max,
  desktop_max,
} from '../../defaults/media-queries'
import { blue, blue_shiny, green, black, orange } from '../../defaults/colors'

export const H1Large = styled.h1({
  fontSize: '55px',
  lineHeight: '100%',
  margin: '0',
  fontWeight: '300',
  color: black,
  [desktop_max]: {
    fontSize: '48px',
  },
  [tablet_max]: {
    fontSize: '40px',
  },
  [phablet_max]: {
    fontSize: '36px',
  },
  [phone_max]: {
    fontSize: '32px',
  },
})

export const H1 = styled.h1({
  fontSize: '45px',
  lineHeight: '100%',
  margin: '0',
  fontWeight: '500',
  color: black,
  [desktop_max]: {
    fontSize: '41px',
  },
  [tablet_max]: {
    fontSize: '37px',
  },
  [phablet_max]: {
    fontSize: '31px',
  },
  [phone_max]: {
    fontSize: '27px',
  },
})

export const H2 = styled.h2({
  fontSize: '37px',
  lineHeight: '100%',
  margin: '0',
  fontWeight: '500',
  color: black,
  [desktop_max]: {
    fontSize: '35px',
  },
  [tablet_max]: {
    fontSize: '33px',
  },
  [phablet_max]: {
    fontSize: '27px',
  },
  [phone_max]: {
    fontSize: '23px',
  },
})

export const H3 = styled.h3({
  fontSize: '29px',
  lineHeight: '130%',
  margin: '0',
  color: black,
  fontWeight: '500',
  '& svg': {
    cursor: 'pointer',
    color: blue,
    fontSize: '18px',
  },
  [desktop_max]: {
    fontSize: '27px',
  },
  [tablet_max]: {
    fontSize: '25px',
  },
  [phablet_max]: {
    fontSize: '23px',
  },
  [phone_max]: {
    fontSize: '21px',
  },
})

export const H4 = styled.h4({
  fontSize: '27px',
  lineHeight: '120%',
  margin: '0',
  color: black,
  fontWeight: '500',
  '& svg': {
    cursor: 'pointer',
    color: blue,
    fontSize: '18px',
  },
  [desktop_max]: {
    fontSize: '25px',
  },
  [tablet_max]: {
    fontSize: '23px',
  },
  [phablet_max]: {
    fontSize: '21px',
  },
  [phone_max]: {
    fontSize: '19px',
  },
})

export const H5 = styled.h5({
  fontSize: '25px',
  lineHeight: '120%',
  margin: '0',
  color: black,
  fontWeight: '500',
  '& svg': {
    cursor: 'pointer',
    color: blue,
    fontSize: '16px',
  },
  [desktop_max]: {
    fontSize: '23px',
  },
  [tablet_max]: {
    fontSize: '21px',
  },
  [phablet_max]: {
    fontSize: '19px',
  },
  [phone_max]: {
    fontSize: '17px',
  },
})

export const H6 = styled.h6({
  fontSize: '23px',
  lineHeight: '120%',
  margin: '0',
  color: black,
  fontWeight: '500',
  '& svg': {
    cursor: 'pointer',
    color: blue,
    fontSize: '16px',
  },
  [desktop_max]: {
    fontSize: '21px',
  },
  [tablet_max]: {
    fontSize: '19px',
  },
  [phablet_max]: {
    fontSize: '17px',
  },
  [phone_max]: {
    fontSize: '15px',
  },
})

export const H1Bold = styled(H1)({fontWeight: 'bold'})
export const H2Bold = styled(H2)({fontWeight: 'bold'})
export const H3Bold = styled(H3)({fontWeight: 'bold'})
export const H4Bold = styled(H4)({fontWeight: 'bold'})
export const H5Bold = styled(H5)({fontWeight: 'bold'})
export const H6Bold = styled(H6)({fontWeight: 'bold'})

export const H1White = styled(H1)({color: 'white'})
export const H2White = styled(H2)({color: 'white'})
export const H3White = styled(H3)({color: 'white'})
export const H4White = styled(H4)({color: 'white'})
export const H5White = styled(H5)({color: 'white'})
export const H6White = styled(H6)({color: 'white'})

export const H1WhiteBold = styled(H1White)({fontWeight: 'bold'})
export const H2WhiteBold = styled(H2White)({fontWeight: 'bold'})
export const H3WhiteBold = styled(H3White)({fontWeight: 'bold'})
export const H4WhiteBold = styled(H4White)({fontWeight: 'bold'})
export const H5WhiteBold = styled(H5White)({fontWeight: 'bold'})
export const H6WhiteBold = styled(H6White)({fontWeight: 'bold'})


export const pStyle = {
  fontSize: '21px',
  lineHeight: '150%',
  margin: '0',
  color: black,
  '& a': {
    color: blue_shiny,
    ':hover': {
      textDecoration: 'underline',
    }
  },
  [tablet_max]: {
    fontSize: '19px',
  },
  [phablet_max]: {
    fontSize: '18px',
  },
  [phone_max]: {
    fontSize: '17px',
  },
}

export const P = styled.p({
  ...pStyle
})

export const pSmallStyle = {
  fontSize: '16px',
  [tablet_max]: {
    fontSize: '15px',
  },
  [phablet_max]: {
    fontSize: '14px',
  },
  [phone_max]: {
    fontSize: '13px',
  },
}

export const PSmall = styled(P)(pSmallStyle)

export const pTinyStyle = {
  fontSize: '13px',
  [tablet_max]: {
    fontSize: '13px',
  },
  [phablet_max]: {
    fontSize: '12px',
  },
  [phone_max]: {
    fontSize: '11px',
  },
}

export const PTiny = styled(P)(pTinyStyle)


export const PWhite = styled(P)({color: 'white'})
export const PSmallWhite = styled(PSmall)({color: 'white'})
export const PTinyWhite = styled(PTiny)({color: 'white'})
export const PWhiteBold = styled(P)({color: 'white', fontWeight: 'bold'})
export const PSmallWhiteBold = styled(PSmall)({color: 'white', fontWeight: 'bold'})
export const PTinyWhiteBold = styled(PTiny)({color: 'white', fontWeight: 'bold'})

export const PSmallEllipsis = styled(PSmall)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'block',
  textOverflow: 'ellipsis',
})

export const PItalic = styled(P)({
  fontStyle: 'italic',
})

export const PSmallBold = styled(PSmall)({
  fontWeight: 'bold',
})

export const PSmallerIndented = styled(PSmall)({
  marginLeft: '20px',
  [phablet_max]: {
    marginLeft: '15px',
  },
  [phone_max]: {
    marginLeft: '10px',
  },
})

export const PSmallCaps = styled(PSmall)({
  textTransform: 'uppercase',
  fontWeight: '500',
  fontSize: '12px',
  [phablet_max]: {
    fontSize: '11px',
  },
  [phone_max]: {
    fontSize: '10px',
  },
})

export const PLarge = styled(P)({
  fontSize: '22px',
  [tablet_max]: {
    fontSize: '20px',
  },
  [phablet_max]: {
    fontSize: '18px',
  },
  [phone_max]: {
    fontSize: '16px',
  },
})

export const PLargeWhite = styled(PLarge)({
  color: 'white'
})

export const PLargeWhiteBold = styled(PLargeWhite)({
  fontWeight: 'bold',
})

export const PBoldOrange = styled(P)({
  display: 'inline',
  fontWeight: '600',
  color: orange
})

export const PBullet = styled.span({
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  background: green,
  display: 'inline-block',
  [tablet_max]: {
    width: '5px',
    height: '5px',
  },
  [phablet_max]: {
    width: '4px',
    height: '4px',
  },
  [phone_max]: {
    width: '3px',
    height: '3px',
  },
})

export const PLink = styled.a({
  color: blue,
  textDecoration: 'underline',
})

export const PBold = styled(P)({
  fontWeight: '600',
})

export const SpanUnderline = styled.span({
  textDecoration: 'underline',
})

export const BoldSpan = styled.span({
  fontWeight: 'bold',
})
