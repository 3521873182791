import React from 'react'
import styled from '@emotion/styled'
import queryString from 'query-string'
import { Global } from '@emotion/core'
import { Section, Container, ContainerLimited, ContentShortV, FlexDiv } from './ui/elements/layout'
import { H2, P, PSmall } from './ui/elements/typography'
import { phablet_max } from './ui/defaults/media-queries'
import { globals } from './global-styles'
const white = '#FEFEFE'
const black = '#021B2C'
function App() {
  const parsed = queryString.parse(window.location.search);
  console.log(parsed)
  const isEnglish = parsed.lang && parsed.lang === 'en'
  return (
    <div>
      <Global styles={globals} />
      <RootSection>
        <HeaderSection>
          <HeaderContainer>
            <CustomContentShortV>
              <FlexDiv>
                <MenuPInline><MenuA href='#about'>{isEnglish ? 'About us' : 'Sobre nós' }</MenuA></MenuPInline>
                <MenuPInline last><MenuA href='#contact'>{isEnglish ? 'Contact' : 'Contacto' }</MenuA></MenuPInline>
              </FlexDiv>
              <div>
                <MenuP><MenuA href={isEnglish ? '/' : '/?lang=en' }>{isEnglish ? 'Português' : 'English' }</MenuA></MenuP>
              </div>
            </CustomContentShortV>
          </HeaderContainer>
        </HeaderSection>
        <Section>
          <Container>
            <br />
            <br />
            <br />
            <br />
            <IMG src='/images/Logo_redondo.jpg' alt="logo" />
          </Container>
          <Container maxWidth={1024} id='video-block'>
          <br />
          <br />
          <br />
          <br />
          <iframe 
            width="100%"
            height="auto"
            style={{aspectRatio: '16 / 9'}}
            src="https://www.youtube.com/embed/2sH2UBoCTFs?si=a5WswZzB405R3g5t"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen>
            </iframe>
          </Container>
          <ContainerLimited maxWidth={600} id='about'>
            <br />
            <br />
            <br />
            <br />
            <br />
            { !isEnglish &&
              <div>
                <CustomH2>Sobre nós</CustomH2>
                <br />
                <TextP>Idealizada em Copenhaga, nascida em Braga. Após algum tempo a viver e trabalhar em Copenhaga, surgiu a vontade de regressar. Inspirada pela cultura escandinava, em particular pela qualidade da sua padaria e pastelaria, fazia todo o sentido trazer um pouco disso para a minha cidade - Braga. Foi no Norte da Europa que encontrei a minha paixão pela padaria artesanal…NØRRE, forma antiga de indicar o Norte em dinamarquês, é, por isso, a expressão que dá sentido a uma padaria idealizada no bairro de Nørrebro e nascida no coração do Norte de Portugal. A NØRRE é uma padaria artesanal. Destaca-se pela utilização de farinhas moídas tradicionalmente em mó de pedra e pelo processo de fermentação lenta e natural com massa-mãe.</TextP>
                <br />
                <TextP>A nossa principal missão é trazer de volta ao mercado um pão saudável, saboroso, nutritivo e de grande durabilidade.</TextP>
              </div>
            }
            { isEnglish &&
              <div>
                <CustomH2>About us</CustomH2>
                <br />
                <TextP>Sketched in Copenhagen, born in Braga. After some time living and working in Copenhagen, there was a desire to move back. Inspired by the scandinavian culture, particularly by the quality of its bakery and pastry, it made perfect sense to bring some of this to my hometown - Braga. It was in Northern Europe that I found my passion for artisanal baking...NØRRE, an ancient form of indicating the North in Danish, is therefore the expression that gives meaning to a bakery idealized in the neighborhood of Nørrebro and born in the heart of the North of Portugal. NØRRE is an artisanal sourdough bakery. It stands out by the use of stoneground flour and the process of slow and natural fermentation.</TextP>
                <br />
                <TextP>Our main mission is to bring back to the market an healthy, tasty and nutritious bread of great durability.</TextP>
              </div>
            }
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </ContainerLimited>
          <ContainerLimited maxWidth={600} id='contact'>
            <br />
            <CenterText>
              { !isEnglish && <CustomH2>Contacto</CustomH2> }
              { isEnglish && <CustomH2>Contact</CustomH2> }
              <br />
              <TextPCentered><Bold>E-mail</Bold> | padaria@norre.pt</TextPCentered>
              <TextPCentered><Bold>Telefone</Bold> | 925 495 657</TextPCentered>
              <TextPCentered><Bold>Morada</Bold> | Rua Alferes Alfredo Ferreira, 71 Braga, Portugal</TextPCentered>
              <TextPCentered><A href='http://www.facebook.com/padarianorre'>Facebook</A> | <A href='http://www.instagram.com/padarianorre'>Instagram</A></TextPCentered>
            </CenterText>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </ContainerLimited>
        </Section>
      </RootSection>
    </div>
  )
}
export default App
const RootSection = styled(Section)({
  display: 'flex',
  flexDirection: 'column',
})
const HeaderSection = styled(Section)({
  background: black,
  display: 'flex',
})
const HeaderContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '600px',
})
const CustomContentShortV = styled(ContentShortV)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
})
const TextP = styled(P)({
  width: '100%',
  textAlign: 'justify',
})
const TextPCentered = styled(TextP)({
  textAlign: 'center',
  width: '100%',
})
const CustomH2 = styled(H2)({
  fontWeight: '300'
})
const CenterText = styled.div({
  textAlign: 'center',
  justifyContent: 'center',
})
const Bold = styled.span({
  fontWeight: 'bold'
})
const A = styled.a({
  //
})
const MenuA = styled.a({
  color: 'white !important',
  fontWeight: '400',
  textDecoration: 'none',
})
const IMG = styled.img({
  width: '40%',
  height: 'auto',
  margin: '0 auto',
  [phablet_max]: {
    width: '80%',
  }
})
const MenuP = styled(PSmall)(props => ({
  color: white,
}))
const MenuPInline = styled(MenuP)(props => ({
  color: white,
  marginRight: props.last ? '0' : '20px'
}))
